import React from "react";
import Education from "../components/Education";

const EducationScreen = () => {
  return (
    <>
      <Education />
    </>
  );
};

export default EducationScreen;
