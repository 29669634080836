import React from "react";
import DrumKit from "../components/projects/DrumKit";

const ProjectPage = () => {
  return (
    <>
      <DrumKit />
    </>
  );
};

export default ProjectPage;
