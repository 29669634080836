import React from "react";
import Timeline from "../components/Timeline";

const ExperienceScreen = () => {
  return (
    <>
      <Timeline />
    </>
  );
};

export default ExperienceScreen;
