import React from "react";
import Hobbies from "../components/Hobbies";

const HobbiesScreen = () => {
  return (
    <>
      <Hobbies />
    </>
  );
};

export default HobbiesScreen;
